import Head from "next/head";
import { GetServerSidePropsContext } from "next";
import {
  UsersUrlParams,
  UsersPage as UsersPageContent,
} from "@/features/Users";

export default function UsersPage({ query }: { query: UsersUrlParams }) {
  return (
    <>
      <Head>
        <title>Users | Hiive BO</title>
      </Head>
      <UsersPageContent query={query} />
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { query } = context;

  return {
    props: { query },
  };
}
